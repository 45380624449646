import React from "react";
// BarChart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
} from "chart.js";

import { Bar } from "react-chartjs-2";

export default function BarChart(props) {
  /* 
    // The data that will receive
    props.data = [ 1, 2, 3, 4, 5, 6, 7 ];
  */

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      position: "top",
    },
    title: {
      display: true,
      text: "Reservas por día",
    },
  };

  const labels = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];

  let dataFromProps = [];
  if (props.data) {
    dataFromProps = props.data;
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Reservas",
        data: props.data ? dataFromProps : [],
        backgroundColor: "#4B70DE",
      },
    ],
  };

  return (
    <div className="bar-chart">
      <div className="dashboard-item">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}
