import React, { useCallback, useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { SERVER } from "../../imports";
import axios from "axios";

export default function Select(props) {
  const [options, setOptions] = useState(["No hay horarios disponibles."]);
  const [selectedOption, setSelectedOption] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Ha ocurrido un error al obtener los turnos disponibles."
  );

  const fetchHorarios = useCallback(async () => {
    await axios
      .get(`${SERVER}/api/shifts`, {
        params: {
          disponible: true,
          date: props.date,
        },
      })
      .then((res) => {
        const data = res.data;
        setOptionsAndSelectedOption(data);
      })
      .catch((err) => {
        setError(true);
        setErrorMessage(err.response.data.message);
        setOptionsAndSelectedOption([]);
      });
  }, [props.date]);

  React.useEffect(() => {
    if (props.date) {
      fetchHorarios();
    }
  }, [props.date, fetchHorarios]);

  function setOptionsAndSelectedOption(data) {
    if (data.length > 0) {
      setOptions(data);
      setSelectedOption([data[0].hora]);
    } else {
      setOptions(["No hay horarios disponibles."]);
      setSelectedOption("");
    }
  }

  function handleChange(e) {
    setSelectedOption(
      Array.from(e.target.selectedOptions, (option) => option.value)
    );
  }

  return (
    <div className={props.divClassName}>
      {error && (
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => setError(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      <label htmlFor={props.id}>
        {props.label}
        {props.isRequired ? <span className="required-symbol">*</span> : null}
      </label>
      <select
        id={props.id}
        name={props.name}
        aria-label={props.ariaLabel}
        onChange={handleChange}
        value={selectedOption}
        required={props.isRequired}
        multiple={props.multiple}
      >
        {options.map((option) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </select>
    </div>
  );
}
