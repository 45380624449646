import React from "react";

// DoughnutChart
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Doughnut } from "react-chartjs-2";

export default function PieChart(props) {

  
  ChartJS.register(ArcElement, Tooltip, Legend);

  const options = {
    responsive: true,
    plugins: {
      position: "top",
    },
    title: {
      display: true,
      text: "Ganancias por mes",
    },
  };

  const [labels, setLabels] = React.useState([
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
  ]);
  const [dataPerMonth, setDataPerMonth] = React.useState([0, 0, 0, 0, 0, 0]);

  React.useEffect(() => {
    if (props.data) {
      setDataPerMonth(props.data.slice(0, 6));
    }
  }, [props.data]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Ganancias aproximadas mensuales",
        data: dataPerMonth,
        backgroundColor: [
          "#4B70DE",
          "#FFC107",
          "#FF5722",
          "#4CAF50",
          "#9C27B0",
          "#00BCD4",
        ],
      },
    ],
  };

  function changeFromJanuaryToJune() {
    setLabels(["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio"]);
    if (props.data) {
      setDataPerMonth(props.data.slice(0, 6));
    }
  }

  function changeFromJulyToDecember() {
    setLabels([
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ]);
    if (props.data) {
      setDataPerMonth(props.data.slice(6, 12));
    }
  }

  function changeMonths() {
    if (labels[0] === "Enero") {
      changeFromJulyToDecember();
    } else {
      changeFromJanuaryToJune();
    }
  }

  function onKeyDown(event) {
    if (event.key === "Enter") {
      changeMonths();
    }
  }

  return (
    <div className="pie-chart">
      <div className="dashboard-item">
        <i
          role="button"
          tabIndex="0"
          aria-roledescription="previous"
          onClick={changeMonths}
          onKeyDown={onKeyDown}
          className="fa-solid fa-angle-left prev"
        ></i>
        <i
          role="button"
          tabIndex="0"
          aria-roledescription="next"
          onClick={changeMonths}
          onKeyDown={onKeyDown}
          className="fa-solid fa-angle-right next"
        ></i>
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
}
