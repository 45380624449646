import React, { useState } from "react";
import axios from "axios";
import { SERVER } from "../../../../../imports";

export default function RangeConstants() {
  const [lunes, setLunes] = useState({
    start: [],
    end: [],
    active: true,
    ranges: 1,
  });

  const [martes, setMartes] = useState({
    start: [],
    end: [],
    active: true,
    ranges: 1,
  });

  const [miercoles, setMiercoles] = useState({
    start: [],
    end: [],
    active: true,
    ranges: 1,
  });

  const [jueves, setJueves] = useState({
    start: [],
    end: [],
    active: true,
    ranges: 1,
  });

  const [viernes, setViernes] = useState({
    start: [],
    end: [],
    active: true,
    ranges: 1,
  });

  const [sabado, setSabado] = useState({
    start: [],
    end: [],
    active: true,
    ranges: 1,
  });

  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);

  const [dataTimeBeforeChanges, setDataTimeBeforeChanges] = useState({
    lunes,
    martes,
    miercoles,
    jueves,
    viernes,
    sabado,
  });

  React.useEffect(() => {
    axios
      .get(`${SERVER}/api/settings`)
      .then((res) => {
        if (res.data) {
          setLunes(res.data.lunes);
          setMartes(res.data.martes);
          setMiercoles(res.data.miercoles);
          setJueves(res.data.jueves);
          setViernes(res.data.viernes);
          setSabado(res.data.sabado);
          setDataTimeBeforeChanges({
            lunes: res.data.lunes,
            martes: res.data.martes,
            miercoles: res.data.miercoles,
            jueves: res.data.jueves,
            viernes: res.data.viernes,
            sabado: res.data.sabado,
          });
        }
        setTimeout(() => {
          setLoading(false);
          setFetchError(false);
        }, 200);
      })
      .catch(() => {
        setFetchError(true);
        setLoading(false);
      });
    
  }, []);
  

  return {
    lunes,
    setLunes,
    martes,
    setMartes,
    miercoles,
    setMiercoles,
    jueves,
    setJueves,
    viernes,
    setViernes,
    sabado,
    setSabado,
    loading,
    fetchError,
    dataTimeBeforeChanges,
    setDataTimeBeforeChanges,
  };
}
