import React from "react";
import { Snackbar, Alert } from "@mui/material";

import axios from "axios";

import { SERVER } from "../../../../imports";
import Card from "../Card/Card";
import StatsSkeleton from "./StatsSkeleton";

export default function Stats() {
  const [stats, setStats] = React.useState([{
    status: true,
    data: "+0%",
    
  }, {
    status: true,
    data: "+0%",
  }]);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get(`${SERVER}/api/stats`, {
        params: {
          rate: true
        }
      })
      .then((res) => {
        setStats(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setError(true);
      });
  }, []);
  
  if (loading) {
    return <StatsSkeleton />;
  }
  return (
    <div className="stats-container">
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert onClose={() => setError(false)} severity="error">
          Ha ocurrido un error al cargar las estadísticas semanales.
        </Alert>
      </Snackbar>
      <div className="dashboard-item">
        <h4 className="stats-title ms-1">Estadísticas semanales</h4>
        <Card
          statName="Reservas"
          tooltipTitle="Reservas realizadas en la última semana en comparación con la semana anterior."
          statStatus={stats[0].data}
          positive={stats[0].status}
          statIcon={stats[0].status ? <i className="fa-solid fa-arrow-trend-up"></i> : <i className="fa-solid fa-arrow-trend-down"></i>}
        />
        <Card
          statName="Clientes"
          tooltipTitle="Clientes registrados en el ultimo mes en comparación con el mes anterior."
          statStatus={stats[1].data}
          positive={stats[1].status}
          statIcon={stats[1].status ? <i className="fa-solid fa-arrow-trend-up"></i> : <i className="fa-solid fa-arrow-trend-down"></i>}
        />
      </div>
    </div>
  );
}
