import React from "react";
// LineChart
import {
  Chart as ChartJS,
  LineController,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Legend,
  Tooltip,
} from "chart.js";

import { Line } from "react-chartjs-2";

export default function LineChart(props) {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    LineController
  );

  const [labels, setLabels] = React.useState([
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
  ]);
  const [dataPerMonth, setDataPerMonth] = React.useState([]);


  React.useEffect(() => {
    if (props.data) {
      setDataPerMonth(props.data.slice(0, 6));
    }
  }, [props.data]);


  const options = {
    responsive: true,
    fill: false,
    tension: 0.1,
    plugins: {
      position: "top",
    },
    title: {
      display: true,
      text: "CLientes por mes",
    },
  };

  const dataChart = {
    labels: labels,
    datasets: [
      {
        label: "Clientes",
        data: dataPerMonth,
        backgroundColor: "#4B70DE",
        pointStyle: "dot",
      },
    ],
  };

  function changeFromJanuaryToJune() {
    setLabels(["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio"]);
    if (props.data) {
      setDataPerMonth(props.data.slice(0, 6));
    }
  }

  function changeFromJulyToDecember() {
    setLabels([
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ]);
    if (props.data) {
      setDataPerMonth(props.data.slice(6, 12));
    }
  }

  function changeMonths() {
    if (labels[0] === "Enero") {
      changeFromJulyToDecember();
    } else {
      changeFromJanuaryToJune();
    }
  }

  function onKeyDown(event) {
    if (event.key === "Enter") {
      changeMonths();
    }
  }

  return (
    <div className="line-chart">
      <div className="dashboard-item">
        <i
          role="button"
          tabIndex="0"
          aria-roledescription="previous"
          onClick={changeMonths}
          onKeyDown={onKeyDown}
          className="fa-solid fa-angle-left prev"
        ></i>
        <i
          role="button"
          tabIndex="0"
          aria-roledescription="next"
          type="button"
          onClick={changeMonths}
          onKeyDown={onKeyDown}
          className="fa-solid fa-angle-right next"
        ></i>
        <Line data={dataChart} options={options} />
      </div>
    </div>
  );
}
