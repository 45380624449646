import React from "react";

import axios from "axios";
import { Snackbar, Alert} from "@mui/material";

// Components
import NavBar from "../../NavBar/NavBar";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import { SERVER } from "../../../imports";

function Main() {

  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  function handleSubmit(e) {
    e.preventDefault();
    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;
    const data = { username, password };

    axios
      .post(SERVER + "/api/login", data)
      .then((response) => {
          window.location.href = "/admin/dashboard";
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setErrorMessage("Usuario o contraseña incorrecta.");
      });
  }
  
  return (
    <div>
      <NavBar />
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert onClose={() => setError(false)} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className="login-container">
        <h2 className="sub-title">Iniciar sesión</h2>
        <form action={SERVER + "/admin/login"} onSubmit={handleSubmit} method="POST">
          <Input
            type="text"
            name="username"
            autocomplete="username"
            id="username"
            divClassName="username-container p-side margin-default"
            placeholder="Nombre de usuario"
            label="Usuario"
            isRequired
            value=""
          />
          <Input
            type="password"
            name="password"
            id="password"
            autocomplete="current-password"
            divClassName="password-container p-side margin-default"
            placeholder="Contraseña"
            label="Contraseña"
            isRequired
            value=""
          />
          <Button
            type="submit"
            divClassName="column centered"
            className="btn"
            buttonBody="Iniciar sesión"
          />
        </form>
      </div>
    </div>
  );
}

export { Main };
