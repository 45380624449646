import React from "react";
import SettingsList from "./SettingsList/SettingsList";
import TimeConfig from "./ScheduleTime/TimeConfig";
import DateConfig from "./ScheduleDates/DateConfig";
export default function SettingsHandler() {
  const [selected, setSelected] = React.useState();
  const cachedSelected = localStorage.getItem("selected");

  const handleListItemClick = (index) => {
    localStorage.setItem("selected", index);
    setSelected(index);
  };

  React.useEffect(() => {
    if (cachedSelected) {
      setSelected(parseInt(cachedSelected));
    } else {
      setSelected(0);
    }
  }, [cachedSelected]);

  return (
    <div className="settings-container">
      <SettingsList
        handleListItemClick={handleListItemClick}
        selected={selected}
      />
      {selected === 0 && <TimeConfig />}
      {selected === 1 && <DateConfig />}
    </div>
  );
}
