import React from "react";
import { Skeleton } from "@mui/material";

export default function TimeConfigSkeleton() {
  return (
    <div className="notification">
      <div className="notification-body">
        <Skeleton variant="rectangular" className="mb-1" height={40} sx={{
          borderRadius: 3,
        }}/>
        <Skeleton variant="rectangular" className="mb-1" height={40} sx={{
          borderRadius: 3,
        }}/>
      </div>
    </div>
  );
} 