import React from "react";
import dayjs from "dayjs";



export default function Input (props) {

  const [input , setInput] = React.useState(props.value);

  function handleChange (e) {
    setInput(e.target.value);
    if (props.multiple) {
      if (parseInt(e.target.value) > 1) {
        props.quantity(parseInt(e.target.value));
        props.multiple(true);
      } else {
        props.quantity(1);
        props.multiple(false);
      }
    }

    if (props.setDate) {
      props.setDate(dayjs(e.target.value).format("YYYY-MM-DD"));
    }
  }

  return (
    <div className={"isValid " + props.divClassName}>
      <label htmlFor={props.id}>{props.label + " "}{props.isRequired ? <span className="required-symbol">*</span> : null}</label>
      <input type={props.type} autoComplete={props.autocomplete} id={props.id} name={props.name} value={input}  pattern={props.pattern} placeholder={props.placeholder} max={props.max} min={props.min} onChange={handleChange} required={props.isRequired} />
      {props.type === "tel" ? <small className="reminder">Formato: 09X1234567</small> : null}
    </div>
  )
}