import axios from "axios";
import { SERVER } from "../../../../imports";

export const chartRequester = async () => {
  const barchart = true;
  const linechart = true;
  const piechart = true;

  try {
    const { data } = await axios.get(`${SERVER}/api/charts`, {
      params: {
        barchart,
        linechart,
        piechart,
      },
    });
    return data;
  } catch (error) {
    console.log("Error al obtener los datos de las graficas");
    return {};
  }
};
