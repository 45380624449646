import React from "react";

import axios from "axios";
import { Snackbar, Alert } from "@mui/material";

// Components
import NavBar from "../../NavBar/NavBar";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import { SERVER } from "../../../imports";

function Main() {
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  function onLogin(e) {
    e.preventDefault();
    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;
    const token = document.getElementById("token").value;
    axios
      .post(SERVER + "/api/signup", {
        username,
        password,
        token,
      })
      .then((response) => {
        window.location.href = "/admin/dashboard";
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        if (err.data.message) {
          setErrorMessage(err.data.message);
          return;
        }
        setErrorMessage("Credenciales inválidas.");
      });
  }

  return (
    <div>
      <NavBar />
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className="login-container">
        <h2 className="sub-title">Registrarse como administrador</h2>
        <form action={SERVER + "/admin/signup"} method="POST">
          <Input
            type="text"
            name="username"
            autocomplete="username"
            id="username"
            divClassName="username-container p-side margin-default"
            placeholder="Nombre de usuario"
            label="Usuario"
            isRequired
            value=""
          />
          <Input
            type="text"
            name="token"
            id="token"
            autocomplete="token"
            divClassName="username-container p-side margin-default"
            placeholder="Token de acceso"
            label="Token de acceso"
            isRequired
            value=""
          />
          <Input
            type="password"
            name="password"
            autocomplete="new-password"
            id="password"
            divClassName="password-container p-side margin-default"
            placeholder="Contraseña"
            label="Contraseña"
            isRequired
            value=""
          />
          <Button
            type="button"
            divClassName="column centered"
            onClick={onLogin}
            className="btn"
            buttonBody="Registrarse"
          />
        </form>
      </div>
    </div>
  );
}

export { Main };
