import React from "react";

export default function NavBar() {


  return (
    <div className="navbar">
      <div className="navbar-logo">
        <a href="/" className="navbar-logo-text">Stylo & Belleza</a>
      </div>
      <div className="navbar-links">
        <div className="navbar-links-item">
          <a href="/agendamientos">Mis agendamientos</a>
        </div>
        <div className="navbar-links-item">
          <a href="/" className="btn">Agendarme</a>
        </div>
      </div>
    </div>
  )
}