import {
  Button,
  FormGroup,
  Tooltip,
  Fade,
  Snackbar,
  Alert,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import RangeSelect from "./RangeSelect";
import RangeConstants from "./RangeConstants";

import React from "react";
import TimeConfigSkeleton from "./TimeConfigSkeleton";
import { SERVER } from "../../../../../imports";

export default function TimeConfig() {
  // Loading
  let constants = RangeConstants();

  // Saving
  const [saving, setSaving] = React.useState(false);
  const [changes, setChanges] = React.useState(false);
  const [saved, setSaved] = React.useState(false);

  // Error handling
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState(
    "Error al guardar los cambios."
  );

  React.useEffect(() => {
    let dataTimeAfterChanges = {
      lunes: constants.lunes,
      martes: constants.martes,
      miercoles: constants.miercoles,
      jueves: constants.jueves,
      viernes: constants.viernes,
      sabado: constants.sabado,
    };
    if (
      JSON.stringify(dataTimeAfterChanges) !==
      JSON.stringify(constants.dataTimeBeforeChanges)
    ) {
      setChanges(true);
    } else {
      setChanges(false);
    }
  }, [constants]);

  React.useEffect(() => {
    if (constants.fetchError) {
      setErrorText("Error al cargar los datos del servidor.");
      setError(true);
      setTimeout(() => {
        setError(false);
        setErrorText("Error al guardar los cambios.");
      }, 3000);
    }
  }, [constants.fetchError]);

  async function handleSave(e) {
    e.preventDefault();
    if (!checkValidity(constants)) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
      return;
    }
    try {
      setSaving(true);
      await axios
        .post(`${SERVER}/api/settings`, constants, {
          params: {
            type: "time",
          },
        })
        .then(() => {
          setSaving(false);
          setSaved(true);
          setChanges(false);
          updateChanges(constants);
          setTimeout(() => {
            setSaved(false);
          }, 3000);
        });
    } catch (error) {
      setSaving(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }

  function checkValidity(constants) {
    let valid = true;
    for (const day in constants) {
      if (constants[day].active) {
        for (let i = 0; i < constants[day].ranges; i++) {
          if (
            constants[day].start.length === 0 ||
            constants[day].end.length === 0
          ) {
            valid = false;
          }
        }
      }
    }
    return valid;
  }

  function updateChanges(constants) {
    constants.setDataTimeBeforeChanges({
      lunes: constants.lunes,
      martes: constants.martes,
      miercoles: constants.miercoles,
      jueves: constants.jueves,
      viernes: constants.viernes,
      sabado: constants.sabado,
    });
  }

  window.onbeforeunload = function () {
    return changes ? "Tienes cambios sin guardar." : null;
  };

  return (
    <div id="time-config" className=" fill centered p-1">
      {saving && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}

      <Snackbar
        open={saved}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Cambios guardados con exito.
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorText}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={changes}
        autoHideDuration={6000}
      >
        <Alert severity="info" sx={{ width: "100%" }}>
          Tienes cambios sin guardar
          <Button
            color="inherit"
            size="small"
            onClick={handleSave}
            disabled={saving}
            sx={{ color: "white", fontWeight: "bold", marginLeft: "1rem" }}
          >
            Guardar
          </Button>
        </Alert>
      </Snackbar>
      <h4 className="ms-1">
        Disponibilidad Horaria{" "}
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title="Agrega rangos de horarios para cada dia de la semana.
          Ejemplo: Lunes de 8:00 a 12:00 y de 14:00 a 18:00"
        >
          <i className="fa-solid fa-circle-info fa-xs"></i>
        </Tooltip>
      </h4>

      {constants.loading ? (
        <TimeConfigSkeleton />
      ) : (
        <div className="ms-1">
          <form id="time-settings">
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "1rem",
                maxWidth: "100%",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RangeSelect
                  label="Lunes"
                  id="monday"
                  day={constants.lunes}
                  setDay={constants.setLunes}
                />
                <RangeSelect
                  label="Martes"
                  id="tuesday"
                  day={constants.martes}
                  setDay={constants.setMartes}
                />
                <RangeSelect
                  label="Miercoles"
                  id="wednesday"
                  day={constants.miercoles}
                  setDay={constants.setMiercoles}
                />
                <RangeSelect
                  label="Jueves"
                  id="thursday"
                  day={constants.jueves}
                  setDay={constants.setJueves}
                />
                <RangeSelect
                  label="Viernes"
                  id="friday"
                  day={constants.viernes}
                  setDay={constants.setViernes}
                />
                <RangeSelect
                  label="Sabado"
                  id="saturday"
                  day={constants.sabado}
                  setDay={constants.setSabado}
                />
              </LocalizationProvider>
              <Button
                type="submit"
                onClick={handleSave}
                variant="contained"
                color="primary"
              >
                Guardar<i className="fa-solid fa-floppy-disk ms-1"></i>
              </Button>
              {changes ? (
                <div>
                  {" "}
                  No se han guardado los cambios
                  <i className="fa-solid fa-triangle-exclamation ms-1"></i>
                </div>
              ) : null}
            </FormGroup>
          </form>
        </div>
      )}
    </div>
  );
}
