import React from "react";
import { useCookies } from "react-cookie";

import NavBar from "../NavBar/NavBar";

function Main() {
  const [cookies] = useCookies(["userScheduling"]);

  return (
    <div>
      <NavBar />
      {cookies.userScheduling ? (
        <div className="agendamientos-container">
          <div className="title-container">
            <h1 className="title">Mis agendamientos</h1>
          </div>
          {cookies.userScheduling.map((scheduling, index) => {
            return (
              <div className="agendamiento" key={index}>
                <i className="fa-regular fa-calendar-check fa-4x"></i>
                <p className="agendamiento-text">Nombre: {scheduling.name}.</p>
                <p className="agendamiento-text">
                  Apellido: {scheduling.surname}.
                </p>
                <p className="agendamiento-text">
                  Email:{" "}
                  {scheduling.email ? scheduling.email : "No proporcionado."}
                </p>
                <p className="agendamiento-text">
                  Teléfono: {scheduling.phone}.
                </p>
                <p className="agendamiento-text">Fecha: {scheduling.date}.</p>
                <p className="agendamiento-text">
                  Cantidad:{" "}
                  {scheduling.quantity > 1
                    ? scheduling.quantity + " personas"
                    : scheduling.quantity + " persona"}
                  .
                </p>
                <p className="agendamiento-text">
                  Horario:{" "}
                  {scheduling.time.length > 1
                    ? scheduling.time.map((time, index) => {
                        if (index === scheduling.time.length - 1) {
                          return <span key={index}>{time}</span>;
                        }
                        return <span key={index}>{time}/</span>;
                      })
                    : scheduling.time}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="agendamientos-container">
          <i className="fa-regular fa-calendar-xmark fa-2x"></i>
          <h2 className="sub-title">No hay agendamientos programados.</h2>
          <a href="/" className="btn">
            Agendarme
          </a>
        </div>
      )}
    </div>
  );
}

export { Main };
