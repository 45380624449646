// React and MUI
import { List, ListItem, Snackbar, Alert, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";

// Third party libraries
import axios from "axios";

// Custom
import { SERVER } from "../../../.././imports.js";
import ClientsSkeleton from "./ClientsSkeleton";

import dayjs from "dayjs";

export default function Clients() {
  // Data
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Status
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("No se pudo cargar los datos.");

  useEffect(() => {
    axios
      .get(`${SERVER}/api/clients`)
      .then((res) => {
        setClients(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 600);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        if (err.response.data.message) setErrorText(err.response.data.message);
      });
  }, []);

  useEffect(() => {
    const searchTermLower = searchTerm.toLowerCase();

    const filteredResults = clients.filter((client) => {
      const {
        name,
        surname,
        email,
        phone,
        preferedTime,
        totalSchedules,
        totalShifts,
      } = client;
      const fullName = `${name} ${surname}`.toLowerCase();

      const nameMatch = name.toLowerCase().includes(searchTermLower);
      const surnameMatch = surname.toLowerCase().includes(searchTermLower);
      const emailMatch = email.toLowerCase().includes(searchTermLower);
      const phoneMatch = phone.toLowerCase().includes(searchTermLower);
      const preferedTimeMatch = preferedTime
        .toLowerCase()
        .includes(searchTermLower);
      const totalSchedulesMatch = totalSchedules
        ?.toString()
        ?.includes(searchTermLower);
      const totalShiftsMatch = totalShifts?.toString().includes(searchTermLower);
      const fullNameMatch = fullName?.includes(searchTermLower);

      return (
        nameMatch ||
        surnameMatch ||
        emailMatch ||
        phoneMatch ||
        preferedTimeMatch ||
        totalSchedulesMatch ||
        totalShiftsMatch ||
        fullNameMatch
      );
    });

    setFilteredClients(filteredResults);
  }, [clients, searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="clients-list">
      <h1 className="clients-list-title">Clientes</h1>
      <TextField
        label="Buscar clientes"
        value={searchTerm}
        onChange={handleSearch}
        fullWidth
        sx={{ mb: 2 }}
      />
      {loading && <ClientsSkeleton />}
      {error && (
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => setError(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorText}
          </Alert>
        </Snackbar>
      )}
      {filteredClients.length !== 0 && !loading && (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "row",
            margin: "0 10px",
            flexWrap: "wrap",
          }}
        >
          {filteredClients.map((client, index) => {
            return (
              <ListItem
                key={index}
                sx={{
                  p: 1,
                  borderRadius: "4px",
                  m: 1,
                  bgcolor: "background.paper",
                  justifyContent: "center",
                  border: "1px solid #e0e0e0",
                  maxWidth: "300px",
                }}
              >
                <div className="client-item">
                  <div className="client-item-name">
                    <h5>{client.name}</h5>
                    <h5>{client.surname}</h5>
                  </div>
                  <div className="client-item-info">
                    <p>
                      Email:{" "}
                      {client.email !== "" ? client.email : "No registrado."}
                    </p>
                    <p>Celular: {client.phone}</p>
                  </div>
                  <div className="client-item-schedule">
                    <p>Cantidad de reservas: {client.totalSchedules}</p>
                    <p>Horario preferido: {client.preferedTime}</p>
                    {/* Hacer la primera letra en mayuscula */}
                    <p>
                      Dia preferido:{" "}
                      {client.preferedDay.charAt(0).toUpperCase() +
                        client.preferedDay.slice(1)}
                    </p>
                    <p>
                      Ultima Reserva:{" "}
                      {dayjs(client.lastSchedule).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </ListItem>
            );
          })}
        </List>
      )}
      {filteredClients.length === 0 && !loading && (
        <div className="flex-row centered">
          <i className="fa-solid fa-person-circle-xmark fa-2x"></i>
          <h3 className="ms-1">No hay clientes registrados.</h3>
        </div>
      )}
    </div>
  );
}
