// React and react-router
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

// Components
import Form from "./Form/Form";
import { Main as MainAgendamientos }  from "./Agendamientos/Main";
import { Main as MainAdminPanel } from './Admin/AdminPanel/Main';
import { Main as MainAdminLogin } from './Admin/Login/Main';
import { Main as MainAdminSignup } from './Admin/Signup/Main';
import Clients from './Admin/AdminPanel/Clients/Clients';
import Dashboard from "./Admin/AdminPanel/Dashboard/DashBoard"
import Settings from './Admin/AdminPanel/Settings/Settings';


// Material UI Theme Configuration
import {useMediaQuery} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

export default function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");


  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
      <CssBaseline />
        <Routes>
          <Route path="/" element={<Form />} />
          <Route path="/agendamientos" element={<MainAgendamientos />} />
          <Route path="/login" element={<MainAdminLogin />} />
          <Route path="/signup" element={<MainAdminSignup />} />
          <Route path="/admin/dashboard" element={<MainAdminPanel children={<Dashboard />}/>} />
          <Route path="/admin/clients" element={<MainAdminPanel children={<Clients />}/>} />
          <Route path="/admin/settings" element={<MainAdminPanel children={<Settings />}/>} />
        </Routes>
      </CookiesProvider>
    </ThemeProvider>
  );
}
