import React from "react";
import { Tooltip, Fade } from "@mui/material";


export default function Card(props) {


  return (
    <div className="card">
      <div className="card-body">
      <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={props.tooltipTitle}
        >
          <i className="fa-solid fa-circle-info p-side"></i>
        </Tooltip>
        <p className="stats">{props.statName}<span className={"stat-status " + (props.positive ? "positive" : "negative")}>{props.statStatus}</span></p>
        {props.statIcon}
      </div>
    </div>
  );
}