// React and MUI
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Snackbar,
  Alert,
} from "@mui/material";

// Third Party
import dayjs from "dayjs";
import axios from "axios";

// Imports
import { SERVER } from "../../../../../imports";

// Custom Components
import Loading from "../../../../Loading/Loading";
import DateConfigSkeleton from "./DateConfigSkeleton";

export default function DateConfig() {
  // Data
  const [dates, setDates] = React.useState([]);
  const [date, setDate] = React.useState(dayjs());

  // Status
  const [saving, setSaving] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  // Error
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");

  // Success
  const [successText, setSuccessText] = React.useState("");

  // Data Grid
  const columns = [
    { field: "id", headerName: "ID" },
    { field: "day", headerName: "Día" },
    { field: "month", headerName: "Mes" },
    { field: "year", headerName: "Año" },
  ];
  const [rows, setRows] = React.useState(
    dates.map((date, index) => {
      return {
        id: index,
        day: date.day,
        month: date.month,
        year: date.year,
      };
    })
  );
  const [selectedRows, setSelectedRows] = React.useState([]);

  // Get the dates from the database
  React.useEffect(() => {
    axios
      .get(`${SERVER}/api/settings`, {
        params: {
          type: "date",
        },
      })
      .then((res) => {
        setError(false);
        setDates(
          res.data.map((date) => {
            return {
              day: dayjs(date).date(),
              month: dayjs(date).month() + 1,
              year: dayjs(date).year(),
            };
          })
        );
        setTimeout(() => {
          setLoading(false);
        }, 600);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setErrorText(`Error: ${err.response.data.message} `);
      });
  }, []);

  // Change rows when dates changes
  React.useEffect(() => {
    setRows(
      dates.map((date, index) => {
        return {
          id: index,
          day: date.day,
          month: date.month,
          year: date.year,
        };
      })
    );
  }, [dates]);

  // Functions
  function showLoading() {
    setSaving(true);
  }

  function showConfirmation() {
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
      setSaving(false);
    }, 2000);
  }

  function showError() {
    setError(true);
    setTimeout(() => {
      setError(false);
      setSaving(false);
    }, 2000);
  }

  // Save the date to the database and add it to the client side
  function saveAndRedirectToSamePage(event) {
    event.preventDefault();
    showLoading();

    axios
      .post(
        `${SERVER}/api/settings`,
        {
          notWorkingDay: date.format("MM-DD-YYYY"),
        },
        {
          params: {
            type: "date",
          },
        }
      )
      .then(() => {
        setSuccessText("Guardado con éxito.");
        showConfirmation();
        setDates([
          ...dates,
          {
            day: date.date(),
            month: date.month() + 1,
            year: date.year(),
          },
        ]);
      })
      .catch((err) => {
        showError();
        setErrorText(`Error: ${err.response.data.message}`);
      });
  }

  function handleChange(newDate) {
    setDate(newDate);
  }

  // Delete the selected dates from the database and remove them from the client side
  function handleDelete() {
    // TODO: Delete dates from database and remove from client side if successful else show error
    showLoading();
    // Send the dates in the body
    axios
      .delete(`${SERVER}/api/settings`, {
        params: {
          type: "date",
        },
        data: {
          dates: selectedRows.map((index) => {
            // Get the date from the dates array and format to MM-DD-YYYY
            return `${dates[index].month}-${dates[index].day}-${dates[index].year}`;
          }),
        },
      })
      .then(() => {
        setSuccessText("Eliminado con éxito.");
        showConfirmation();
        setDates(
          dates.filter((date, index) => {
            return !selectedRows.includes(index);
          })
        );
      })
      .catch((err) => {
        showError();
        setErrorText(`Error: ${err.response.data.message}`);
      });
  }

  return (
    <div className="ms-1 fill centered p-1">
      {saving && !saved && !error && <Loading />}
      {saving && saved && (
        <Snackbar open autoHideDuration={2000}>
          <Alert severity="success">{successText}</Alert>
        </Snackbar>
      )}
      {saving && error && (
        <Snackbar open autoHideDuration={2000}>
          <Alert severity="error">{errorText}</Alert>
        </Snackbar>
      )}
      {loading && <DateConfigSkeleton />}
      {!loading && (
        <form onSubmit={saveAndRedirectToSamePage}>
          <FormGroup>
            <FormLabel
              sx={{
                fontFamily: "Noto Sans, sans-serif",
                fontWeight: 400,
                fontStyle: "normal",
              }}
              className="mt-1 mb-1"
              component="legend"
            >
              Fecha donde no estare disponible
            </FormLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  fontFamily: "Noto Sans, sans-serif",
                  fontWeight: 400,
                  fontStyle: "normal",
                }}
                label="Fecha"
                format="DD/MM/YYYY"
                value={date}
                onChange={handleChange}
              />
            </LocalizationProvider>
            <div className="mt-1">
              <Button variant="contained" type="submit">
                <i className="fa-solid fa-circle-plus icon-sizer"></i>
              </Button>
            </div>
          </FormGroup>
        </form>
      )}
      {!loading && (
        <div className="pt-1">
          <Box sx={{ width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              autoHeight
              sx={{
                "@media (max-width: 550px)": {
                  maxWidth: "260px",
                },
                fontFamily: "Noto Sans, sans-serif",
                fontWeight: 400,
                fontStyle: "normal",
              }}
              onRowSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection);
              }}
            />
          </Box>
          <div className="mt-1 flex-row flex-space-between">
            <Button onClick={handleDelete} variant="contained">
              <i className="fa-solid fa-circle-minus icon-sizer"></i>
            </Button>

            {/* <Button variant="contained">
            <i className="fa-solid fa-save icon-sizer"></i>
          </Button> */}
          </div>
        </div>
      )}
    </div>
  );
}
