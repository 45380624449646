import React from "react";


export default function Button (props) {
  

  return (
    <div className={props.divClassName}>
      <button id={props.id} type={props.type} className={props.className} onClick={props.onClick}>{props.buttonBody}</button>
    </div>
  )
}