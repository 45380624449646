import React from "react";

export default function Modal(props) {
  function closeModal() {
    document.getElementById(props.id + " " + props.kei).style.display = "none";
    if (props.setEdit) {
      props.setEdit(false);
    }
  }

  function onKeyDown(e) {
    if (e.keyCode === 27) {
      closeModal();
    }
  }

  return (
    <div
      key={props.kei}
      id={props.id + " " + props.kei}
      className={"modal " + props.class}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{props.title}</h2>
          <span
            tabIndex={"0"}
            role={"button"}
            className="close"
            onKeyDown={onKeyDown}
            onClick={closeModal}
          >
            <i className="fa-sharp fa-solid fa-circle-xmark"></i>
          </span>
        </div>
        <div className="modal-body">{props.body}</div>
        <div className="modal-footer">{props.footer}</div>
      </div>
    </div>
  );
}
