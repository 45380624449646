import { List } from "@mui/material";
import React from "react";

import axios from "axios";
import dayjs from "dayjs";

import ScheduleItem from "./ScheduleItem";
import { SERVER } from "../../../../imports";

export default function Schedule() {
  const [schedule, setSchedule] = React.useState([
    {
      name: "Sin",
      surname: "Reservas",
      date: Date.now(),
    }
  ]);

  React.useEffect(() => {
    axios
      .get(`${SERVER}/api/shifts`, {
        params: {
          // Include all shifts greater than today including time
          gt: dayjs().format("YYYY-MM-DDTHH:mm").toString(),
        }
      })
      .then((res) => {
        if (res.data.length === 0) return;
        setSchedule(res.data);
      })
      .catch(() => {});
  }, []);

  return (
    <div className="schedule-container ms-1">
      <div className="dashboard-item pb-1">
        <h4>Proximas Reservas</h4>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            borderRadius: "4px",
            p: 0.5,
            maxHeight: 500,
            overflow: "auto",
          }}
        >
          {schedule.map((appointment, index) => {
            return (
              <ScheduleItem key={index} appointment={appointment} />
            );
          })}
        </List>
      </div>
    </div>
  );
}
