import React from "react";


export default function Notification (props) {
  return (
    <div className="notification">
      <div className="notification-body">
        {props.notificationBody === "No hay notificaciones." ? null : <i className="fa-solid fa-circle-user"></i> }
        <p>{props.notificationBody}</p>
        <div className="notification-date">
          <small>{props.notificationDate}</small>
        </div>
      </div>
    </div>
  );
}