import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import {onBackgroundMessage} from "firebase/messaging/sw";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "stylo-y-belleza-374300.firebaseapp.com",
  projectId: "stylo-y-belleza-374300",
  storageBucket: "stylo-y-belleza-374300.appspot.com",
  messagingSenderId: "798852224564",
  appId: "1:798852224564:web:78d4cb66dbd3a4f17e1c61",
  measurementId: "G-V0B4ZNNNDB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const onBackgroundMessageListener = () => {
  return new Promise((resolve) => {
    onBackgroundMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};


export default app;