import React from "react";

import { Masonry } from "@mui/lab";
import { chartRequester } from "../Charts/chartRequester.js";

import Schedule from "../Schedule/Schedule";
import Stats from "../Stats/Stats";
import BarChart from "../Charts/BarChart";
import LineChart from "../Charts/LineChart";
import PieChart from "../Charts/PieCharts";

export default function Dashboard () {

  const [data, setData] = React.useState({
    barchart: null,
    linechart: null,
    piechart: null,
  });

  React.useEffect(() => {
    async function fetchData() {
      const data = await chartRequester();
      setData(data);
    };
    fetchData();
  }, []);

  return (
    <Masonry columns={{xs:1, sm: 1, md:2, lg: 3 }} spacing={0.5}
      sx={{
        width: "100%",
      }}
    >
      <Stats />
      <Schedule />
      <BarChart data={data.barchart} />
      <LineChart data={data.linechart} />
      <PieChart data={data.piechart} />
    </Masonry>
  );
}