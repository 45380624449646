import React from "react";


// Components
import AdminNavBar from "./AdminNavBar/AdminNavBar";



function Main(props) {


  return (
    <div>
      <AdminNavBar />
      {props.children}
    </div>
  );
}

export { Main };