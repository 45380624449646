import React from "react";
import { Skeleton } from "@mui/material";

export default function ClientsSkeleton() {
  return (
    <div className="ms-1 flex-row g-1">
      <Skeleton variant="rectangular" className="mb-1"  height={150} sx={{
        borderRadius: 3,
        width: "40vw",
        height: "100%",
      }}/>
      <Skeleton variant="rectangular" className="mb-1"  height={150} sx={{
        borderRadius: 3,
        width: "40vw",
        height: "100%",
      }}/>
      <Skeleton variant="rectangular" className="mb-1"   height={150} sx={{
        borderRadius: 3,
        width: "40vw",
        height: "100%",
      }}/>
      <Skeleton variant="rectangular" className="mb-1"   height={150} sx={{
        borderRadius: 3,
        width: "40vw",
        height: "100%",
      }}/>
    </div>
  );
} 