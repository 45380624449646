import React from "react";
import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Snackbar,
  Alert,
} from "@mui/material";

// Components
import SimpleModal from "../../../Modal/SimpleModal";
import Input from "../../../Input/Input";
import Select from "../../../Input/Select";
import Button from "../../../Button/Button";
import Loading from "../../../Loading/Loading";
import { SERVER } from "../../../../imports";

import dayjs from "dayjs";
import "dayjs/locale/es.js";
import axios from "axios";

export default function ScheduleItem(props) {
  dayjs.locale("es");
  // Data
  const [modalTitle, setModalTitle] = React.useState("Editar Cita");
  const [modalBody, setModalBody] = React.useState("");
  const [modalFooter, setModalFooter] = React.useState("");
  const [date, setDate] = React.useState(props.appointment.date);
  // Status
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [edit, setEdit] = React.useState(false);

  React.useEffect(() => {
    if (edit) {
      setModalTitle("Editar Cita");
      setModalBody(
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <Input
              divClassName="email-container"
              id="name"
              value={props.appointment.name}
              label="Nombre"
              type="text"
              name="name"
              placeholder="Nombre"
              isRequired
            />
            <Input
              divClassName="email-container"
              id="surname"
              value={props.appointment.surname}
              label="Apellido"
              type="text"
              name="surname"
              placeholder="Apellido"
              isRequired
            />

            <Input
              divClassName="email-container"
              id="email"
              value={props.appointment.email}
              label="Email"
              type="email"
              name="email"
              placeholder="Email"
              isRequired={false}
            />
            <Input
              divClassName="phone-container"
              id="phone"
              value={props.appointment.phone}
              label="Teléfono"
              type="tel"
              name="phone"
              placeholder="09X1234567"
              pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
              isRequired
            />
            <Input
              divClassName="date-container"
              id="date"
              label="Fecha"
              setDate={setDate}
              type="date"
              value={dayjs(date).format("YYYY-MM-DD")}
              name="date"
              placeholder="Fecha"
              isRequired
              min={new Date().toISOString().split("T")[0]}
            />
            <Select
              divClassName="time-container"
              id="time"
              label="Horarios "
              name="horario"
              ariaLabel="Horarios"
              multiple={false}
              date={dayjs(date).format("YYYY-MM-DD")}
              isRequired
            />
            <Button
              divClassName="button-centered-container"
              type="submit"
              className="btn"
              buttonBody="Editar"
            />
            {console.log(dayjs(date).format("YYYY-MM-DD"))}
          </div>
        </form>
      );
      setModalFooter("");
      document.getElementById(
        "schedule-modal " + props.appointment._id
      ).style.display = "block";
    }
  }, [edit, date]);

  function handleSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const name = form.name.value;
    const surname = form.surname.value;
    const email = form.email.value;
    const phone = form.phone.value;
    const date = form.date.value;

    const select = document.getElementById("time");
    const time = Array.from(select.selectedOptions).map(
      (option) => option.value
    );
    if (time.length === 0) {
      setError(true);
      setErrorMessage("Debe seleccionar al menos un horario.");
      return;
    } else if (time[0] === "No hay horarios disponibles.") {
      setError(true);
      setErrorMessage(
        "No hay horarios disponibles para la fecha seleccionada."
      );
      return;
    }
    const data = {
      _id: props.appointment._id,
      name,
      surname,
      email,
      phone,
      date,
      time,
    };
    axios
      .patch(`${SERVER}/api/shifts`, data)
      .then(() => {
        setIsLoading(false);
        setError(false);
        setSuccess(true);
        setSuccessMessage("Cita editada con éxito!");
        document.getElementById(
          "schedule-modal " + props.appointment._id
        ).style.display = "none";
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        setSuccess(false);
        setError(true);
        err.response.data.message
          ? setErrorMessage(err.response.data.message)
          : setErrorMessage(
              "Error desconocido, por favor intente de nuevo o contacte al dueño del sitio."
            );
      });
  }

  function handleDelete() {
    setModalTitle("Esta seguro que desea eliminar la cita?");
    setModalBody(
      <div className="flex-row">
        <Button
          type="button"
          className="btn-delete"
          buttonBody="Eliminar"
          onClick={deleteAppointment}
        />
        <Button
          type="button"
          className="btn ms-1"
          buttonBody="Cancelar"
          onClick={() => {
            document.getElementById(
              "schedule-modal " + props.appointment._id
            ).style.display = "none";
          }}
        />
      </div>
    );
    setModalFooter("");
    document.getElementById(
      "schedule-modal " + props.appointment._id
    ).style.display = "block";
  }

  function deleteAppointment() {
    setIsLoading(true);
    axios
      .delete(`${SERVER}/api/shifts`, {
        params: {
          id: props.appointment._id,
        },
      })
      .then(() => {
        setIsLoading(false);
        setError(false);
        setSuccess(true);
        setSuccessMessage("Cita eliminada con éxito!");
        document.getElementById(
          "schedule-modal " + props.appointment._id
        ).style.display = "none";
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        setSuccess(false);
        setError(true);
        err.response.data.message
          ? setErrorMessage(err.response.data.message)
          : setErrorMessage(
              "Error desconocido, por favor intente de nuevo o contacte al dueño del sitio."
            );
      });
  }

  function handleEdit() {
    setEdit(true);
  }

  return (
    <ListItem>
      {isLoading && <Loading />}
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <SimpleModal
        kei={props.appointment._id}
        id="schedule-modal"
        setEdit={setEdit}
        class="scroll-hide"
        title={modalTitle}
        body={modalBody}
        modalFooter={modalFooter}
      />
      <ListItemAvatar>
        <i className="fa-solid fa-calendar-week"></i>
      </ListItemAvatar>

      <ListItemText
        primary={props.appointment.name + " " + props.appointment.surname}
        secondary={
          dayjs(props.appointment.date).format("dddd DD MMMM YYYY") +
          " a las " +
          dayjs(props.appointment.date).format("HH:mm") +
          " hs."
        }
      />
      {props.appointment.name !== "Sin" ? (
        <ListItemButton
          onClick={handleDelete}
          sx={{
            color: "#FF0000",
            "&:hover": {
              color: "#800000",
              backgroundColor: "#FF4D4D",
            },
            justifyContent: "center",
          }}
        >
          <i className="fa-solid fa-trash-can icon-sizer"></i>
        </ListItemButton>
      ) : null}
      {props.appointment.name !== "Sin" ? (
        <ListItemButton
          onClick={handleEdit}
          sx={{
            justifyContent: "center",
            color: "#FFEF0C",
            "&:hover": {
              color: "#FFD700",
              backgroundColor: "#FFEF0C",
            },
          }}
        >
          <i className="fa-solid fa-pen-to-square icon-sizer"></i>
        </ListItemButton>
      ) : null}
    </ListItem>
  );
}
