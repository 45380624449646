import React from "react";
import { Skeleton } from "@mui/material";

export default function DateConfigSkeleton() {
  return (
    <div className="ms-1">
      <Skeleton variant="rectangular" className="mb-1" width={250}  height={20} sx={{
        borderRadius: 3,
      }}/>
      <Skeleton variant="rectangular" className="mb-1"  height={130} sx={{
        borderRadius: 3,
      }}/>
      <Skeleton variant="rectangular" className="mb-1" width={80}  height={50} sx={{
        borderRadius: 3,
      }}/> 
      <Skeleton variant="rectangular" className="mb-1"  height={150} sx={{
        borderRadius: 3,
      }}/>
      <Skeleton variant="rectangular" className="mb-1" width={80}  height={50} sx={{
        borderRadius: 3,
      }}/>
    </div>
  );
} 