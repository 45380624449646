import React from "react";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { Masonry } from "@mui/lab";
import dayjs from "dayjs";

export default function RangeSelect(props) {
  const start = props.day.start;
  const end = props.day.end;
  const rangesToRender = props.day.ranges; // This is the number of ranges to render


  function dateToFormatedString(date) {
    return dayjs(date).format("YYYY-MM-DDTHH:mm");
  }

  function handleAddRange() {
    props.setDay({
      ...props.day,
      ranges: rangesToRender + 1,
      start: [
        ...start,
        dateToFormatedString(dayjs(start[rangesToRender - 1]).add(1, "hour")),
      ],
      end: [
        ...end,
        dateToFormatedString(dayjs(end[rangesToRender - 1]).add(1, "hour")),
      ],
      active: props.day.active,
    });
  }

  function handleDeleteRange() {
    if (rangesToRender > 1) {
      props.setDay({
        ...props.day,
        ranges: rangesToRender - 1,
        start: start.slice(0, rangesToRender - 1),
        end: end.slice(0, rangesToRender - 1),
        active: props.day.active,
      });
    }
  }

  return (
    <div className="styled-border">
      <FormControlLabel
        control={
          <Switch
            name={props.id + "-active"}
            checked={props.day.active}
          />
        }
        label={props.label}
        labelPlacement="start"
        onChange={(event) => {
          props.setDay({
            ...props.day,
            active: event.target.checked,
          });
        }}
      />
      {Array.from(Array(rangesToRender).keys()).map((index) => (
        <Masonry
          key={index}
          columns={2}
          sx={{
            marginBottom: "0.5rem",
          }}
        >
          <TimePicker
            minTime={index === 0 ? null : dayjs(end[index - 1])} // null si es el primero, si no, Mayor a la hora de fin del rango anterior
            format="HH:mm"
            slotProps={{
              textField: {
                helperText: "Hora de inicio",
              },
            }}
            value={start.length > 0 ? dayjs(start[index]) : null}
            onChange={(newValue) => {
              // Save the new value in the state to its corresponing index
              if (newValue) {
                props.setDay({
                  ...props.day,
                  start: [
                    ...start.slice(0, index),
                    dateToFormatedString(newValue.$d),
                    ...start.slice(index + 1),
                  ],
                });
              } else {
                props.setDay({
                  ...props.day
                });
              }
            }}
          />
          <TimePicker
            minTime={dayjs(start[index])} // Mayor a la hora de inicio del rango actual
            // Formato 24 horas
            format="HH:mm"
            slotProps={{
              textField: {
                helperText: "Hora de fin",
              },
            }}
            value={start.length > 0 ? dayjs(end[index]) : null}
            onChange={(newValue) => {
              if (newValue) {
                props.setDay({
                  ...props.day,
                  end: [
                    ...end.slice(0, index),
                    dateToFormatedString(newValue.$d),
                    ...end.slice(index + 1),
                  ],
                });
              } else {
                props.setDay({
                  ...props.day
                });
              }
            }}
          />
        </Masonry>
      ))}
      <Button onClick={() => handleAddRange()}>
        <i className="fa-solid fa-circle-plus icon-sizer"></i>
      </Button>
      <Button onClick={() => handleDeleteRange()}>
        <i className="fa-solid fa-circle-minus icon-sizer"></i>
      </Button>
    </div>
  );
}
