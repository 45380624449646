// React and MUI
import React from "react";
import { useCookies } from "react-cookie";
import { Snackbar, Alert, Fab } from "@mui/material";

// Custom
import Input from "../Input/Input";
import Select from "../Input/Select";
import NavBar from "../NavBar/NavBar";
import Button from "../Button/Button";
import { getTokenFromSW } from "./NotificationHandler";
import { SERVER } from "../../imports";

// Third party
import axios from "axios";
import dayjs from "dayjs";

export default function Form() {
  // Data
  const [multiple, setMultiple] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  const [date, setDate] = React.useState(dayjs().format("YYYY-MM-DD"));
  // Status
  const [isLoading, setIsLoading] = React.useState(false);
  const [scheduled, setScheduled] = React.useState(false);

  // Cookies
  const [cookie, setCookie] = useCookies(["userScheduling"]);

  // Error
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    const data = await prepareForPost(e);

    if (!data) return;

    axios
      .post(`${SERVER}/api/shifts`, data)
      .then(() => {
        setIsLoading(false);
        createUserCookie(data);
        setScheduled(true);
        setError(false);
        setTimeout(() => {
          // Redirect to /agendamientos
          window.location.href = "/agendamientos";
        }, 4000);
      })
      .catch((err) => {
        setIsLoading(false);
        setScheduled(false);
        setError(true);
        err.response.data.message
          ? setErrorMessage(err.response.data.message)
          : setErrorMessage(
              "Error desconocido, por favor intente de nuevo o contacte al dueño del sitio."
            );
      });
  }

  function createUserCookie(data) {
    const userScheduling = {
      name: data.name,
      surname: data.surname,
      email: data.email,
      phone: data.phone,
      date: data.date,
      quantity: data.quantity,
      time: data.time,
    };

    // Expira 1 dia despues de la fecha seleccionada
    const cookieOptions = {
      path: "/",
      expires: new Date(data.date + "T23:59:59"),
    };
    //save like an array if there is already a cookie
    if (cookie.userScheduling) {
      const newCookie = [...cookie.userScheduling, userScheduling];
      setCookie("userScheduling", newCookie, cookieOptions);
    } else {
      setCookie("userScheduling", [userScheduling], cookieOptions);
    }
  }

  async function prepareForPost(e) {
    const form = e.target;
    const name = form.name.value;
    const surname = form.surname.value;
    const email = form.email.value;
    const phone = form.phone.value;
    const date = form.date.value;
    const quantity = form.quantity.value;

    const select = document.getElementById("time");
    const time = Array.from(select.selectedOptions).map(
      (option) => option.value
    );
    if (time.length === 0 || time.length !== parseInt(quantity)) {
      setError(true);
      setErrorMessage(`Por favor, seleccione ${quantity} horarios.`);
      setIsLoading(false);
      return;
    } else if (time[0] === "No hay horarios disponibles.") {
      setError(true);
      setErrorMessage(`La fecha ${date} no tiene horarios disponibles.`);
      setIsLoading(false);
      return;
    }
    let token = null;
    // try {
    //   token = await getTokenFromSW();
    // } catch (err) {
    //   console.log(err);
    // }

    if (!token) {
      return {
        name,
        surname,
        email,
        phone,
        date: dayjs(date).format("YYYY-MM-DD"),
        quantity,
        time,
      };
    }

    return {
      name,
      surname,
      email,
      phone,
      date: dayjs(date).format("YYYY-MM-DD"),
      quantity,
      time,
      token: token,
    };
  }

  return (
    <div>
      <Snackbar
        open={error || scheduled}
        autoHideDuration={6000}
        onClose={() => {
          setError(false);
          setScheduled(false);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{marginTop: "50%"}}
      >
        <Alert
          onClose={() => {
            setError(false);
            setScheduled(false);
          }}
          severity={error ? "error" : "success"}
          sx={{
            width: "100%",
            fontSize: "24px",
            backgroundColor: error ? "#f44336" : "#4caf50", // Color de fondo diferente para error y éxito
            color: "#ffffff", // Color de texto blanco
            fontWeight: "bold", // Fuente en negrita
            boxShadow: "none", // Sin sombra
            borderRadius: "0", // Sin bordes redondeados
            textAlign: "center", // Centrar texto
            padding: "16px" // Espaciado interno
          }}
        >
          {error ? errorMessage : "Su turno ha sido agendado con éxito!"}
        </Alert>
      </Snackbar>
      <NavBar />
      <div className="title-container">
        <h2 className="sub-title">
          Formulario de agendamiento para caballeros.
        </h2>
        <small className="reminder">
          Los campos marcados con <span className="required-symbol">*</span> son
          obligatorios.
        </small>
      </div>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="form-row">
              <Input
                divClassName="name-container"
                id="name"
                value=""
                label="Nombre"
                type="text"
                name="name"
                placeholder="Nombre"
                isRequired
              />
              <Input
                divClassName="surname-container"
                id="surname"
                value=""
                label="Apellido"
                type="text"
                name="surname"
                placeholder="Apellido"
                isRequired
              />
            </div>
            <Input
              divClassName="phone-container"
              id="phone"
              value=""
              label="Teléfono"
              type="tel"
              name="phone"
              placeholder="09X1234567"
              pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
              isRequired
            />
            <Input
              divClassName="email-container"
              id="email"
              value=""
              label="Email"
              type="email"
              name="email"
              placeholder="Email"
              isRequired={false}
            />

            <Input
              divClassName="date-container"
              id="date"
              label="Fecha"
              type="date"
              value={date}
              setDate={setDate}
              name="date"
              placeholder="Fecha"
              isRequired
              min={dayjs().format("YYYY-MM-DD")}
            />
            <Input
              divClassName="quantity-container"
              id="quantity"
              multiple={setMultiple}
              quantity={setQuantity}
              label="Cantidad de Personas"
              type="number"
              name="quantity"
              value={1}
              placeholder="Cantidad de Personas"
              isRequired
              min={1}
              max={5}
            />
            <small className="quantity-reminder">Maximo 5 personas !</small>
            <small className="reminder">
              Si desea agendar para más de una persona, seleccione la cantidad
              de personas y luego seleccione los horarios.
            </small>
            <Select
              divClassName="time-container"
              id="time"
              label="Horarios "
              name="horario"
              ariaLabel="Horarios"
              quantity={quantity}
              multiple={multiple}
              date={date}
              isRequired
            />
            {isLoading ? (
              <div className="button-centered-container">
                <i className="fa-solid fa-spinner fa-3x fa-spin"></i>
              </div>
            ) : (
              <Button
                id="submit-button"
                divClassName="button-centered-container"
                type="submit"
                className="btn"
                buttonBody="Agendar"
              />
            )}
          </div>
        </form>
      </div>
      {/* Fab for maps */}
      <a
        href="https://goo.gl/maps/BibZ9AefpZsjzZPg8"
        target="_blank"
        rel="noreferrer"
      >
        <Fab
          sx={{
            position: "fixed",
            bottom: "100px",
            right: "20px",
            zIndex: "5",
            backgroundColor: "#4285F4",
            "&:hover": {
              backgroundColor: "#F4B400",
            },
          }}
          color="inherit"
          aria-label="add"
          className="fab"
        >
          <i className="fa-solid fa-map-marker-alt fa-2x"></i>
        </Fab>
      </a>

      {/* Fab for Whatsapp */}
      <a href="https://wa.me/595981514044" target="_blank" rel="noreferrer">
        <Fab
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: "5",
            backgroundColor: "#25D366",
            "&:hover": {
              backgroundColor: "#128C7E",
            },
          }}
          color="inherit"
          aria-label="add"
          className="fab"
        >
          <i
            color="inherit"
            aria-label="add"
            className="fa-brands fa-whatsapp fa-2x"
          ></i>
        </Fab>
      </a>
    </div>
  );
}
