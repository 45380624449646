// React and MUI
import React from "react";
import {
  Badge,
  Box,
  Menu,
  MenuItem,
  Popper,
  Snackbar,
  Alert,
} from "@mui/material";

// Third party
import axios from "axios";

// Components
import Notifications from "../Notification/Notification";
import NotificationsSkeleton from "../Notification/NotificationSkeleton";
import { SERVER } from "../../../../imports.js";

export default function AdminNavBar() {
  const [openNot, setOpenNot] = React.useState(false);
  const [openDrop, setOpenDrop] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [getMoreNotifications, setGetMoreNotifications] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [notificationsCount, setNotificationsCount] = React.useState(0);
  const [notifications, setNotifications] = React.useState([
    {
      body: "No hay notificaciones.",
      _id: "1",
    },
  ]);
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    if (page === 1) {
      axios
        .get(`${SERVER}/api/notifications`, {
          params: {
            limit: 10,
            page: page,
          },
        })
        .then((res) => {
          setGetMoreNotifications(false);
          if (res.data.length !== 0) {
            setNotifications(res.data);
            for (const notification of res.data) {
              if (!notification.read) {
                setNotificationsCount((prev) => prev + 1);
              }
            }
          }
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [page]);

  React.useEffect(() => {
    if (getMoreNotifications) {
      axios
        .get(`${SERVER}/api/notifications`, {
          params: {
            limit: 10,
            page: page,
          },
        })
        .then((res) => {
          setGetMoreNotifications(false);
          if (res.data.length !== 0) {
            setNotifications((prev) => [...prev, ...res.data]);
          }
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [getMoreNotifications, page]);

  function handleNotfClick() {
    setOpenDrop(false);
    setOpenNot((prev) => !prev);
    setAnchorEl(document.getElementById("notf-icon"));
    setNotificationsCount(0);
  }

  function handleDropdownMenu() {
    setOpenNot(false);
    setOpenDrop((prev) => !prev);
    setAnchorEl(document.getElementById("dropdown-btn"));
  }

  function handleClose() {
    setOpenDrop(false);
    setAnchorEl(null);
  }

  function handleNotficationScroll(e) {
    const element = e.target;

    if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
      setPage((prev) => prev + 1);
      setGetMoreNotifications(true);
    }
  }

  function onKeyDown(e) {
    if (e.key === "Enter") {
      handleNotfClick();
    }
  }

  return (
    <div className="navbar">
      <Snackbar open={notificationsCount > 0} autoHideDuration={3000}>
        <Alert severity="info" sx={{ width: "100%" }}>
          Tienes {notificationsCount} notificaciones nuevas.
        </Alert>
      </Snackbar>

      <Snackbar
        open={error}
        autoHideDuration={3000}
        onClose={() => setError(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Hubo un error al cargar las notificaciones.
        </Alert>
      </Snackbar>

      <div className="navbar-logo">
        <a href="/admin/dashboard" className="navbar-logo-text">
          Stylo & Belleza
        </a>
      </div>
      <div className="navbar-links notifications">
        <div className="p-side">
          <Badge badgeContent={notificationsCount} color="error">
            <i
              role={"button"}
              tabIndex={0}
              id="notf-icon"
              className="fa-solid fa-bell"
              onClick={handleNotfClick}
              onKeyDown={onKeyDown}
            ></i>
          </Badge>
        </div>

        <Popper id="notification-popper" open={openNot} anchorEl={anchorEl}>
          <Box
            mt={2.5}
            mr={1}
            ml={1}
            onScroll={handleNotficationScroll}
            sx={{
              p: 1.5,
              maxHeight: "500px",
              bgcolor: "background.paper",
              borderRadius: "4px",
              overflow: "auto",
              maxWidth: "85vw",
              border: "1px solid #d6dee1",
              // custom scrollbar
              "&::-webkit-scrollbar": {
                width: "0.7rem",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#d6dee1",
                borderRadius: "4px",
                border: "2px solid transparent",
                backgroundClip: "content-box",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            }}
          >
            {notifications.map((notification, index) => {
              return (
                <Notifications
                  key={notification._id}
                  notificationBody={notification.body}
                />
              );
            })}
            {getMoreNotifications && <NotificationsSkeleton />}
          </Box>
        </Popper>
      </div>
      <div className="dropdown">
        <button
          id="dropdown-btn"
          className="btn dropbtn"
          onClick={handleDropdownMenu}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
        <Menu
          id="dropdown-menu"
          open={openDrop}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <a className="text-decoration-none" href="/admin/dashboard">
            <MenuItem onClick={handleClose}>
              <i className="fa-solid fa-chart-line p-side"></i>Home
            </MenuItem>
          </a>
          <a className="text-decoration-none" href="/admin/clients">
            <MenuItem onClick={handleClose}>
              <i className="fa-regular fa-address-book p-side"></i>Clientes
            </MenuItem>
          </a>
          <a className="text-decoration-none" href="/admin/settings">
            <MenuItem onClick={handleClose}>
              <i className="fa-solid fa-gears p-side"></i>Configuración
            </MenuItem>
          </a>
          <a className="text-decoration-none" href="/api/logout">
            <MenuItem onClick={handleClose}>
              <i className="fa-solid fa-right-from-bracket p-side"></i>Cerrar
              sesión
            </MenuItem>
          </a>
        </Menu>
      </div>
    </div>
  );
}
