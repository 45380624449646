import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import React from "react";

export default function SettingsList(props) {
  return (
    <List
      sx={{
        bgcolor: "background.paper",
        flexDirection: "column",
        padding: 0,
        overflow: "hidden",
        minWidth: "50px",
        "@media (max-width: 768px)": {
            maxWidth: "50px",
          }
      }}
    >
      <ListSubheader sx={
        {
          "@media (max-width: 768px)": {
            display: "none",
          }
        }
      } component="div" id="nested-list-subheader">
        Configuración
      </ListSubheader>
      <ListSubheader sx={
        {
          "@media (min-width: 768px)": {
            display: "none",
          }
        }
      } component="div" id="nested-list-subheader">
        <i className="fa-solid fa-gears icon-sizer"></i>
      </ListSubheader>
      <ListItemButton
        selected={props.selected === 0}
        onClick={() => {
          props.handleListItemClick(0);
        }}
      >
        <ListItemIcon>
          <i className="fa-solid fa-clock icon-sizer"></i>
        </ListItemIcon>
        <ListItemText className="responsive-hide" primary="Horarios" />
      </ListItemButton>
      <ListItemButton
        selected={props.selected === 1}
        onClick={() => {
          props.handleListItemClick(1);
        }}
      >
        <ListItemIcon>
          <i className="fa-solid fa-calendar-days icon-sizer"></i>
        </ListItemIcon>
        <ListItemText className="responsive-hide" primary="Fechas" />
      </ListItemButton>
    </List>
  );
}
