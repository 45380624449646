import React from "react";
import { Skeleton } from "@mui/material";

export default function StatsSkeleton() {
  return (
    <div className="stats-container">
      <Skeleton
        variant="rectangular"
        className="mb-1"
        height={150}
        sx={{
          borderRadius: 3,
        }}
      />
      <Skeleton
        variant="rectangular"
        className="mb-1"
        height={150}
        sx={{
          borderRadius: 3,
        }}
      />
    </div>
  );
}
